import React from 'react';
import Helmet from 'react-helmet';

const Insta = () => {
  return (
    <>
    <div class="elfsight-app-a8463649-370a-4a1a-b939-aa453df7da0e"></div>
    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
    </Helmet>
  </>
);
}

export default Insta
