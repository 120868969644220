import React, { useState } from "react"
import { Link } from "gatsby"

import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Vitrine from "../components/vitrine"
import Animation from "../components/animation"
import ModalDevis from "../components/modalDevis"
import Call from "../components/call"
import Insta from "../components/insta"
/*import GrillePhoto from "../components/grillePhotos"*/

import logo_img from "../images/LVDV_logo.svg"

import icn_1 from "../images/icone_1.svg"
import icn_2 from "../images/icone_2.svg"
import icn_3 from "../images/icone_3.svg"

import facebook from "../images/icn_facebook.svg"
import instagram from "../images/icn_instagram.svg"

import fl_w from "../images/icn_fl_retour_w.svg"

import {
  liste_vitrines,
  tel,
  tel_lien,
  mail,
  adr_1,
  adr_2,
  LIEN_INSTAGRAM,
  LIEN_FACEBOOK,
} from "../datas/vitrines"

const IndexPage = () => {
  const [devisVisible, setDevisVisible] = useState(false)

  return (
    <Layout>
      <Seo title="Les Vitrines de Valérie" />

      <div className="bg-[url('../images/les-vitrines-de-valerie.jpg')] head_site bg-cover bg-center flex items-center flex-col justify-center align-items-center relative">
        <div className="z-10 flex items-center flex-col justify-center align-items-center">
          <img
            src={logo_img}
            alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg"
          />
          <h1 className="text-5xl md:text-7xl text-white my-12 text-center">
            LES VITRINES DE VALERIE
          </h1>
          <h2 className="font-light	text-white text-2xl max-w-xl text-center">
            Location de vitrines, de courte et longue durée en France, en
            Belgique et au Luxembourg
          </h2>
          <AnchorLink to={"/#LVDV"} title="Découvrir les vitrines">
            <img
              src={fl_w}
              className=" w-5 mt-12 cursor-pointer animate-bounce"
              alt="Location de vitrines d'exposition"
            />
          </AnchorLink>
        </div>

        <div className="bottom-0 absolute md:-rotate-90 right-0 md:bottom-16 z-10">
          <p className="text-white/36 m-0">Photo vitrine</p>
          <p className="text-white m-0">Bouisset Bernard</p>
        </div>
        <div className="voile w-full h-full absolute pointer-events-none bg-black/50"></div>
      </div>

      <div className="bg-gris-lvdv" id="LVDV">
        <div className="bg-white md:mx-20 px-5 flex items-center flex-col justify-center align-items-center">
          <ul className="m-0 text-center">
            {liste_vitrines.map((v, i) => (
              <li
                key={i}
                className="ease-in-out duration-300 group hover:bg-produits inline-block	p-5 m-0 cursor-pointer"
              >
                <AnchorLink
                  className="ease-in-out duration-300 no-underline text-black group-hover:text-white"
                  to={"/#" + v.slug}
                  title="Tarifs"
                >
                  {v.nom}
                </AnchorLink>
              </li>
            ))}
          </ul>

          <div className="md:flex items-start justify-center align-items-top my-20 md:mx-12 mx-2">
            <div>
              <img
                src={icn_1}
                alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg"
                className="mb-10 m-auto md:mx-0"
              />
              <p className="font-bold">
                Notre GAMME est bâtie pour répondre aux besoins des antiquaires,
                maisons de ventes aux enchères, joailliers, créateurs,
                bouquinistes …
              </p>
            </div>
            <div className="md:my-0 md:mx-12">
              <img
                src={icn_2}
                alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg"
                className="mb-10 m-auto md:mx-0"
              />
              <p className="font-bold">
                Nos vitrines se distinguent par leur élégance, leur sobriété
                énergétique (100% LED) et leur praticité (étagères et pieds
                réglables, spots orientables et choix des températures de
                couleur).
              </p>
            </div>
            <div>
              <img
                src={icn_3}
                alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg"
                className="mb-10 m-auto md:mx-0"
              />
              <p className="font-bold">
                Nous nous chargeons de la livraison, de la mise en place sur
                site, de la reprise et assurons la maintenance dans les 4
                heures.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-produits pt-32">
        {liste_vitrines.map((v, i) => (
          <Vitrine
            key={i}
            type={v.type}
            nom={v.nom}
            description={v.description}
            hauteur={v.hauteur}
            largeur={v.largeur}
            profondeur={v.profondeur}
            lumiere={v.lumiere}
            consommation={v.consommation}
            photos={v.photos}
            prix={v.prix}
            slug={v.slug}
            mw={v.mw}
            legendePhoto={v.legendePhoto}
            diapo={v.diapo}
          />
        ))}
      </div>

      <div className="lg:flex">
        <div className="lg:w-5/12 py-28 bg-tarifs" id="tarifs">
          <div className="px-5 sm:px-20">
            <h4 className="relative text-6xl text-white after:content-[''] after:ml-0.5 after:h-2 after:w-14 after:bg-sous-titre-produit after:absolute after:top-2/4 after:left-0 after:-left-20 after:opacity-40">
              Tarifs
            </h4>
            <span className="text-sous-titre-produit text-sm">
              prix nets HT
            </span>

            <ul className="m-0 mt-11">
              {liste_vitrines.map((v, i) => {
                if(v.prix !== "") {
                  return (
                    <li
                      key={i}
                      className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center"
                    >
                      <p className="text-base text-white uppercase m-0">{v.nom}</p>
                      <div className="h-px grow bg-white/25 mx-2"></div>
                      <p className="text-base text-white m-0">{v.prix}</p>
                    </li>
                  )
                }
                })}
            </ul>

            {/*<h4 className="relative text-6xl text-white after:content-[''] after:ml-0.5 after:h-2 after:w-14 after:bg-sous-titre-produit after:absolute after:top-2/4 after:left-0 after:-left-20 after:opacity-40 mt-11">
              Et aussi
            </h4>
            <ul className="m-0 mt-11">
              <li className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center">
                <p className="text-base text-white uppercase m-0">Rallonges</p>
                <div className="h-px grow bg-white/25 mx-2"></div>
                <p className="text-base text-white m-0">5€ HT/ml</p>
              </li>

              <li className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center">
                <p className="text-base text-white uppercase m-0">
                  Multi-prises
                </p>
                <div className="h-px grow bg-white/25 mx-2"></div>
                <p className="text-base text-white m-0">10€ HT pièce</p>
              </li>
              <li className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center">
                <p className="text-base text-white uppercase m-0">
                  Multi-prises avec disjoncteur
                </p>
                <div className="h-px grow bg-white/25 mx-2"></div>
                <p className="text-base text-white m-0">30€ HT pièce</p>
              </li>
              <li className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center">
                <p className="text-base text-white uppercase m-0">
                  Passages de câbles
                </p>
                <div className="h-px grow bg-white/25 mx-2"></div>
                <p className="text-base text-white m-0">15€ HT/ml</p>
              </li>
              <li className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center">
                <p className="text-base text-white uppercase m-0">Chaises</p>
                <div className="h-px grow bg-white/25 mx-2"></div>
                <p className="text-base text-white m-0">30€ HT pièce</p>
              </li>
              <li className="py-3 m-0 cursor-pointer flex items-center justify-center align-items-center">
                <p className="text-base text-white uppercase m-0">
                  Raccordement
                </p>
                <div className="h-px grow bg-white/25 mx-2"></div>
                <p className="text-base text-white m-0">15€ HT par vitrine</p>
              </li>
            </ul>*/}
          </div>
        </div>
        <div className="lg:w-7/12 py-28" id="devis-contact">
          <div className="px-5 sm:px-20 lg:max-w-3xl">
            <h4 className="relative text-6xl text-black mb-14 after:content-[''] after:ml-0.5 after:h-2 after:w-14 after:bg-sous-titre-produit after:absolute after:top-2/4 after:left-0 after:-left-20 after:opacity-40">
              Devis
            </h4>
            {/*<p>
              Les prix nets HT incluent la livraison réalisée par nos soins, la
              mise en place des vitrines, leur récupération au terme de
              l’événement.{" "}
            </p>
            <p>
              Ils s’appliquent pour une durée de location de 1 à 14 jours, pour
              une quantité d’au moins 5 vitrines et pour une zone géographique
              correspondant à la petite couronne parisienne (départements 75,
              92, 93, 94).
            </p>
            <p>
              Au-delà de ce périmètre ou pour moins de 5 vitrines louées des
              frais de transport seront facturés sur une base de 0,55€ HT le
              kilomètre plus frais de péage autoroutier calculés au départ de
              notre entrepôt de Plaisir (78).
            </p>
            <p>
              Si la livraison à lieu en étage et en l’absence de monte-charge un
              forfait sera appliqué.
            </p>*/}

            <button
              href="#"
              className="pointer bg-black px-4 py-2 text-white no-underline rounded-lg mt-9 inline-block border border-black hover:text-black hover:bg-white ease-in-out duration-300"
              onClick={() => {
                setDevisVisible(true)
              }}
            >
              Demander un devis
            </button>
          </div>
        </div>
      </div>
      <Call />
      <Insta />
      <div className="bg-black">
      {/*<GrillePhoto />*/}
      </div>
      <footer className="bg-[url('../images/les-vitrines-de-valerie.jpg')] min-h-screen bg-cover bg-center flex items-center flex-col justify-center align-items-center relative">
        <div className="z-10 flex items-center flex-col justify-center align-items-center my-20">
          <h4 className="text-7xl md:text-xxxxxl text-white/20 my-12">LVDV</h4>
          <h4 className="text-5xl text-white md:text-6xl text-center">
            Philippe d'Esterno
          </h4>
          <div className="flex items-center justify-start align-items-center mt-5">
            <a href={LIEN_INSTAGRAM} target="_blank" rel="noreferrer">
              <img
                src={instagram}
                className="w-20"
                alt="Les Vitrines de Valérie sur Instagram"
              />
            </a>
            <a href={LIEN_FACEBOOK} target="_blank" rel="noreferrer">
              <img
                src={facebook}
                className="w-16"
                alt="Les Vitrines de Valérie sur Facebook"
              />
            </a>
          </div>
          <div className="sep my-12 h-2 w-14 bg-sous-titre-produit opacity-40"></div>

          <a
            href={"tel:" + tel_lien}
            target="_blank"
            rel="noreferrer"
            className="inline-block text-xl	text-white no-underline"
          >
            {tel}
          </a>
          <a
            href={"mailto:" + mail}
            target="_blank"
            rel="noreferrer"
            className="inline-block text-xl	text-white no-underline"
          >
            {mail}
          </a>

          <a
            href="https://www.google.com/maps/place/37+Rue+d'Amsterdam,+75008+Paris/@48.879031,2.3245379,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66e4a55ee055b:0xd63aaaa15dfaeb02!8m2!3d48.8790275!4d2.3267266"
            target="_blank"
            rel="noreferrer"
            className="text-center inline-block text-xl	text-white no-underline mt-11"
          >
            {adr_1}
            <br />
            {adr_2}
          </a>

          <a
            href="https://www.lesvitrinesdevalerie.fr/vcard.vcf"
            target="_blank"
            rel="noreferrer"
            className="text-center inline-block text-xl	text-white no-underline mt-11"
          >
            Ajoutez-nous à vos contacts !
          </a>
        </div>

        <div className="voile w-full h-full absolute pointer-events-none bg-black/80"></div>

        <div className="px-2 absolute w-full flex items-center justify-between bottom-9 sm:px-11">
          <p className="uppercase text-white/60 m-0">Les vitrines de Valérie</p>
          <Link
            className="uppercase text-white/60 no-underline"
            to={"/mentions-legales"}
          >
            Mentions légales
          </Link>
        </div>
      </footer>

      <Animation />

      {devisVisible && (
        <ModalDevis
          devisVisible={devisVisible}
          setDevisVisible={setDevisVisible}
        />
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Les Vitrines de Valérie" />

export default IndexPage
