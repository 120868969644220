import React, { useState,  } from 'react';
import logo_img from "../images/LVDV_logo.svg";

const Animation = () => {
  const [animeVisible, setAnimeVisible] = useState(true);
  
  
  return (
    <>
    {animeVisible && (
      <div className="fade-out-final fixed z-30 top-0 left-0 cursor-pointer" onClick={() => {setAnimeVisible(false)}} onKeyDown={() => {setAnimeVisible(false)}} >
        <div className="h-screen w-screen anime_home flex items-center flex-col justify-center align-items-center fixed top-0 left-0 ">
          <div className="h-screen w-screen bg-[url('../images/les-vitrines-de-valerie.jpg')] bg-cover bg-center kenburns-bottom fixed top-0 left-0"></div>
          <div className="h-screen w-screen bg-black fixed top-0 left-0 fade-presque-in"></div>
          <div className="h-screen w-screen bg-black fixed top-0 left-0 fade-out"></div>
          
          
          
          
          <img src={logo_img} alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg" className="z-30 scale-in-center-zoom" />
          
          <p className="text-5xl md:text-7xl text-white my-12 text-center titre_head fade-in-titre  absolute">LES VITRINES DE VALERIE</p>
        </div>
      </div>
    )}
  </>
);
}

export default Animation
