import React from 'react';
import Helmet from 'react-helmet';

const Call = () => {
  return (
    <>
    <div className="elfsight-app-ff849983-cb5d-4cde-88ed-27beb2b0de4d"></div>
    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
    </Helmet>
  </>
);
}

export default Call
