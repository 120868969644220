import React, { useEffect, useState, useRef } from 'react';


import icn_retour from "../images/icn_fl_retour.svg";

import { liste_vitrines, tel, tel_lien, mail } from '../datas/vitrines';

const ModalDevis = ({devisVisible, setDevisVisible}) => {
  const [dateJ, setDateJ] = useState("");
  const formLDVD = useRef(null);
  
  
  function getCurrentDate(separator=''){
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`

  }
  
  useEffect(() => {
    setDateJ(getCurrentDate('-'));
  }, []);
  
  const envoyer_form = (e) => {
    //setDevisVisible(false);
    if(formLDVD.current.reportValidity()) {
      console.log("send !!");
    }else {
      console.log("error !!");
    }
    return false;
  }
  
  
  return (
    <div className='fixed w-screen h-screen top-0 left-0 bg-white/80 sm:flex items-center flex-col justify-center align-items-center z-30 overflow-auto'>
      <div className='max-w-4xl w-full relative'>
        <h2 className='text-4xl text-black mb-16 text-4xl mb-11 text-center'>FORMULAIRE DE DEVIS</h2>
        <div className='relative mx-auto w-36 mb-5 sm:mb-0  sm:absolute flex items-center justify-center align-items-center hover:opacity-80 ease-in-out duration-300 cursor-pointer sm:top-0 mt-2 group ' onClick={() => {setDevisVisible(false)}}>
          <img src={icn_retour} className="m-0 mr-2 group-hover:animate-retour-modal" alt="Les Vitrines de Valérie" />
          <span>Retour</span>
        </div>
        <div className='bg-white drop-shadow-xl w-full px-14 py-7 '>
          <form action='https://getform.io/f/e0a343cd-274f-42ee-b111-e2ebd0a6e291' method='post' ref={formLDVD}>
            <div className='sm:flex items-center'>
              <div className='w-full sm:w-1/2 sm:border-r sm:border-tarifs sm:mr-3 sm:pr-6'>
                <div className='md:flex'>
                  <div className='w-full md:w-1/2 md:mr-2'>
                    <div className='mb-5'>
                      <label htmlFor="nom" className='font-bold block'>Nom<sup>*</sup></label>
                      <input type="text" id="nom" className='bg-lvdv-input border-bord-input w-full' name="nom" required/>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 sm:ml-2'>
                    <div className='mb-5'>
                      <label htmlFor="prenom" className='font-bold block'>Prénom<sup>*</sup></label>
                      <input type="text" id="prenom" className='bg-lvdv-input border-bord-input w-full' name="prenom" required/>
                    </div>
                  </div>
                </div>
                <div className='mb-5'>
                  <label htmlFor="mail" className='font-bold block'>Adresse mail<sup>*</sup></label>
                  <input type="email" id="mail" className='bg-lvdv-input border-bord-input w-full' name="mail" required/>
                </div>
                <div className='mb-5'>
                  <label htmlFor="telephone" className='font-bold block'>Téléphone</label>
                  <input type="text" id="telephone" className='bg-lvdv-input border-bord-input w-full' name="telephone"/>
                </div>
                <div className='mb-5'>
                  <label htmlFor="raison_sociale" className='font-bold block'>Raison sociale<sup>*</sup></label>
                  <input type="text" id="raison_sociale" className='bg-lvdv-input border-bord-input w-full' name="raison_sociale" required/>
                </div>
                <div className='mb-5'>
                  <label htmlFor="localisation" className='font-bold block'>Localisation<sup>*</sup></label>
                  <input type="text" id="localisation" className='bg-lvdv-input border-bord-input w-full' name="localisation" required/>
                </div>
                
              
                
              </div>
              <div className='w-full sm:w-1/2 sm:ml-3'>
                <div className='mb-5'>
                  <label htmlFor="vitrines" className='font-bold block'>Vitrines</label>
                  <div className='grid grid-cols-1 grid-rows-4 md:gap-4 md:grid-cols-2 md:grid-rows-2'>
                  {liste_vitrines.map((v, i) => (
                    
                    <div key={i}>
                      <input type="checkbox" name={v.slug} id={v.slug} className="w-4 h-4 text-black bg-gray-100 border-black focus:ring-black focus:ring-2" />
                      <label htmlFor={v.slug} className="ml-2 inline-block">{v.nom}</label>
                    </div>
                    
                    
                  ))}
                  </div>
                </div>
                <div className='md:flex'>
                  <div className='w-full md:w-1/2 md:mr-2'>
                    <div className='mb-5'>
                      <label htmlFor="debut" className='font-bold block'>Date de début </label>
                      <input type="date" id="debut" name="debut"
                       className='bg-lvdv-input border-bord-input w-full'
                       min={dateJ} />
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 md:ml-2'>
                    <div className='mb-5'>
                      <label htmlFor="fin" className='font-bold block'>Date de fin </label>
                      <input type="date" id="fin" name="fin"
                       className='bg-lvdv-input border-bord-input w-full'
                       min={dateJ} />
                    </div>
                  </div>
                </div>
                <div className='mb-5'>
                  <label htmlFor="commentaire" className='font-bold block'>Commentaire</label>
                  <textarea id="commentaire" name='commentaire' className='bg-lvdv-input border-bord-input w-full h-44' ></textarea>
                </div>
              </div>
            </div>
            
            <div className='flex items-center flex-col justify-center align-items-center'>
              <button className="cursor-pointer bg-black px-4 py-2 text-white no-underline rounded-lg mt-9 inline-block border border-black hover:text-black hover:bg-white ease-in-out duration-300" onClick={(e) => envoyer_form(e)} >Envoyer</button>
            </div>
          </form>
        </div>
        <div className='mt-11 flex items-center justify-center align-items-center mb-5'>
          <a href={"tel:"+tel_lien} target="_blank" rel="noreferrer" className="mx-5 inline-block	text-black no-underline">{tel}</a>
          <a href={"mail:"+mail} target="_blank" rel="noreferrer" className="mx-5 inline-block text-black no-underline">{mail}</a>
        </div>
      </div>
    </div>
);
}

export default ModalDevis
